var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var PopForums;
(function (PopForums) {
    PopForums.AreaPath = "/Forums";
    function Ready(callback) {
        if (document.readyState != "loading")
            callback();
        else
            document.addEventListener("DOMContentLoaded", callback);
    }
    PopForums.Ready = Ready;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class ElementBase extends HTMLElement {
        connectedCallback() {
            if (this.state && this.propertyToWatch)
                return;
            let stateAndWatchProperty = this.getDependentReference();
            this.state = stateAndWatchProperty[0];
            this.propertyToWatch = stateAndWatchProperty[1];
            const delegate = this.update.bind(this);
            this.state.subscribe(this.propertyToWatch, delegate);
        }
        update() {
            const externalValue = this.state[this.propertyToWatch];
            this.updateUI(externalValue);
        }
    }
    PopForums.ElementBase = ElementBase;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    // Properties to watch require the @WatchProperty attribute.
    class StateBase {
        constructor() {
            this._subs = new Map();
        }
        subscribe(propertyName, eventHandler) {
            if (!this._subs.has(propertyName))
                this._subs.set(propertyName, new Array());
            const callbacks = this._subs.get(propertyName);
            callbacks.push(eventHandler);
            eventHandler();
        }
        notify(propertyName) {
            const callbacks = this._subs.get(propertyName);
            if (callbacks)
                for (let i of callbacks) {
                    i();
                }
        }
    }
    PopForums.StateBase = StateBase;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    PopForums.WatchProperty = (target, memberName) => {
        let currentValue = target[memberName];
        Object.defineProperty(target, memberName, {
            set(newValue) {
                currentValue = newValue;
                this.notify(memberName);
            },
            get() { return currentValue; }
        });
    };
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class AnswerButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get answerstatusclass() {
            return this.getAttribute("answerstatusclass");
        }
        get chooseanswertext() {
            return this.getAttribute("chooseanswertext");
        }
        get topicid() {
            return this.getAttribute("topicid");
        }
        get postid() {
            return this.getAttribute("postid");
        }
        get answerpostid() {
            return this.getAttribute("answerpostid");
        }
        get userid() {
            return this.getAttribute("userid");
        }
        get startedbyuserid() {
            return this.getAttribute("startedbyuserid");
        }
        get isfirstintopic() {
            return this.getAttribute("isfirstintopic");
        }
        connectedCallback() {
            this.button = document.createElement("p");
            this.button.classList.add("icon");
            this.answerstatusclass.split(" ").forEach((c) => this.button.classList.add(c));
            if (this.isfirstintopic.toLowerCase() === "false" && this.userid === this.startedbyuserid) {
                // make it a button for author
                this.button.addEventListener("click", () => {
                    PopForums.currentTopicState.setAnswer(Number(this.postid), Number(this.topicid));
                });
            }
            this.appendChild(this.button);
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "answerPostID"];
        }
        updateUI(answerPostID) {
            if (this.isfirstintopic.toLowerCase() === "false" && this.userid === this.startedbyuserid) {
                // this is question author
                this.button.classList.add("asnswerButton", "fs-1", "my-3");
                if (answerPostID && this.postid === answerPostID.toString()) {
                    this.button.classList.remove("icon-check-circle");
                    this.button.classList.remove("text-muted");
                    this.button.classList.add("icon-check-circle-fill");
                    this.button.classList.add("text-success");
                    this.style.cursor = "default";
                }
                else {
                    this.button.classList.remove("icon-check-circle-fill");
                    this.button.classList.remove("text-success");
                    this.button.classList.add("icon-check-circle");
                    this.button.classList.add("text-muted");
                    this.style.cursor = "pointer";
                }
            }
            else if (answerPostID && this.postid === answerPostID.toString()) {
                // not the question author, but it is the answer
                this.button.classList.add("icon-check-circle-fill");
                this.button.classList.add("text-success");
                this.style.cursor = "default";
            }
        }
    }
    PopForums.AnswerButton = AnswerButton;
    customElements.define('pf-answerbutton', AnswerButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class CommentButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        get topicid() {
            return this.getAttribute("topicid");
        }
        get postid() {
            return this.getAttribute("postid");
        }
        connectedCallback() {
            var _a;
            this.innerHTML = CommentButton.template;
            let button = this.querySelector("button");
            button.title = this.buttontext;
            if (((_a = this.buttonclass) === null || _a === void 0 ? void 0 : _a.length) > 0)
                this.buttonclass.split(" ").forEach((c) => button.classList.add(c));
            if (button.classList.contains("btn"))
                button.innerText = this.buttontext;
            button.addEventListener("click", (e) => {
                PopForums.currentTopicState.loadComment(Number(this.topicid), Number(this.postid));
            });
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "commentReplyID"];
        }
        updateUI(data) {
            let button = this.querySelector("button");
            if (data !== undefined) {
                button.disabled = true;
                button.style.cursor = "default";
            }
            else
                button.disabled = false;
        }
    }
    CommentButton.template = `<button type="button"></button>`;
    PopForums.CommentButton = CommentButton;
    customElements.define('pf-commentbutton', CommentButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class FavoriteButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get makefavoritetext() {
            return this.getAttribute("makefavoritetext");
        }
        get removefavoritetext() {
            return this.getAttribute("removefavoritetext");
        }
        connectedCallback() {
            this.innerHTML = PopForums.SubscribeButton.template;
            let button = this.querySelector("button");
            this.buttonclass.split(" ").forEach((c) => button.classList.add(c));
            button.addEventListener("click", () => {
                fetch(PopForums.AreaPath + "/Favorites/ToggleFavorite/" + PopForums.currentTopicState.topicID, {
                    method: "POST"
                })
                    .then(response => response.json())
                    .then(result => {
                    switch (result.data.isFavorite) {
                        case true:
                            PopForums.currentTopicState.isFavorite = true;
                            break;
                        case false:
                            PopForums.currentTopicState.isFavorite = false;
                            break;
                        default:
                        // TODO: something else
                    }
                })
                    .catch(() => {
                    // TODO: handle error
                });
            });
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "isFavorite"];
        }
        updateUI(data) {
            let button = this.querySelector("button");
            if (data) {
                button.title = this.removefavoritetext;
                button.classList.remove("icon-star", "text-muted");
                button.classList.add("icon-star-fill", "text-warning");
            }
            else {
                button.title = this.makefavoritetext;
                button.classList.remove("icon-star-fill", "text-warning");
                button.classList.add("icon-star", "text-muted");
            }
        }
    }
    FavoriteButton.template = `<button type="button" class="btn-link icon"></button>`;
    PopForums.FavoriteButton = FavoriteButton;
    customElements.define('pf-favoritebutton', FavoriteButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class FormattedTime extends HTMLElement {
        constructor() {
            super();
        }
        get utctime() {
            return this.getAttribute("utctime");
        }
        connectedCallback() {
            const delegate = this.ready.bind(this);
            this.isReady = PopForums.LocalizationService.subscribe(delegate);
            if (this.isReady)
                this.ready();
        }
        ready() {
            this.setBaseTime();
            let now = Date.now();
            let yesterdayMs = now - FormattedTime.dayInMs;
            let yesterdayTemp = new Date(yesterdayMs);
            let yesterday = new Date(yesterdayTemp.getFullYear(), yesterdayTemp.getMonth(), yesterdayTemp.getDate());
            this.innerHTML = this.GetDisplayTime();
            if (this.utcTime > yesterday.getTime())
                this.UpdateTimer();
            this.isReady = true;
        }
        setBaseTime() {
            let baseTime = this.utctime;
            if (!baseTime.endsWith("Z"))
                baseTime = baseTime + "Z";
            this.utcTime = Date.parse(baseTime);
            this.utcTimeAsDate = new Date(baseTime);
        }
        UpdateTimer() {
            setTimeout(() => {
                this.UpdateTimer();
                this.innerHTML = this.GetDisplayTime();
            }, 60000);
        }
        GetDisplayTime() {
            let now = Date.now();
            let nowAsDate = new Date();
            let diff = now - this.utcTime;
            let yesterdayMs = now - 86400000;
            let yesterdayTemp = new Date(yesterdayMs);
            let yesterday = new Date(yesterdayTemp.getFullYear(), yesterdayTemp.getMonth(), yesterdayTemp.getDate());
            const dateOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
            const timeOptions = { hour: "numeric", minute: "2-digit" };
            if (diff > 3599000) {
                // more than an hour
                if (this.utcTimeAsDate.toLocaleDateString() === nowAsDate.toLocaleDateString())
                    return PopForums.localizations.todayTime.replace("{0}", this.utcTimeAsDate.toLocaleTimeString(undefined, timeOptions));
                if (this.utcTimeAsDate.toLocaleDateString() === yesterday.toLocaleDateString())
                    return PopForums.localizations.yesterdayTime.replace("{0}", this.utcTimeAsDate.toLocaleTimeString(undefined, timeOptions));
                return this.utcTimeAsDate.toLocaleDateString(undefined, dateOptions) + " " + this.utcTimeAsDate.toLocaleTimeString(undefined, timeOptions);
            }
            if (diff > 120000)
                return PopForums.localizations.minutesAgo.replace("{0}", Math.round(diff / 60000).toString());
            if (diff > 60000)
                return PopForums.localizations.oneMinuteAgo;
            return PopForums.localizations.lessThanMinute;
        }
        static get observedAttributes() { return ["utctime"]; }
        attributeChangedCallback(name, oldValue, newValue) {
            if (name === "utctime" && this.isReady) {
                this.setBaseTime();
                this.innerHTML = this.GetDisplayTime();
                this.UpdateTimer();
            }
        }
    }
    FormattedTime.dayInMs = 86400000;
    PopForums.FormattedTime = FormattedTime;
    customElements.define('pf-formattedtime', FormattedTime);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class FullText extends PopForums.ElementBase {
        constructor() {
            super();
            this.editorSettings = {
                target: null,
                plugins: "lists image link",
                content_css: FullText.editorCSS,
                menubar: false,
                toolbar: "cut copy paste | bold italic | bullist numlist blockquote removeformat | link | image imageup",
                statusbar: false,
                link_target_list: false,
                link_title: false,
                image_description: false,
                image_dimensions: false,
                image_title: false,
                image_uploadtab: false,
                images_file_types: 'jpeg,jpg,png,gif',
                automatic_uploads: false,
                browser_spellcheck: true,
                object_resizing: false,
                relative_urls: false,
                remove_script_host: false,
                contextmenu: "",
                paste_as_text: true,
                paste_data_images: false,
                setup: null,
                height: null
            };
        }
        get overridelistener() {
            return this.getAttribute("overridelistener");
        }
        get forcenoimage() {
            return this.getAttribute("forcenoimage");
        }
        get isshort() {
            return this.getAttribute("isshort");
        }
        get formID() { return this.getAttribute("formid"); }
        ;
        get value() { return this._value; }
        set value(v) { this._value = v; }
        connectedCallback() {
            var _a, _b, _c, _d, _e;
            var initialValue = this.getAttribute("value");
            if (initialValue)
                this.value = initialValue;
            if (PopForums.userState.isPlainText) {
                this.externalFormElement = document.createElement("textarea");
                this.externalFormElement.id = this.formID;
                this.externalFormElement.setAttribute("name", this.formID);
                this.externalFormElement.classList.add("form-control");
                if (this.value)
                    this.externalFormElement.value = this.value;
                this.externalFormElement.rows = 12;
                if (((_a = this.isshort) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "true")
                    this.externalFormElement.rows = 3;
                let self = this;
                this.externalFormElement.addEventListener("change", () => {
                    self.value = this.externalFormElement.value;
                });
                this.appendChild(this.externalFormElement);
                if (((_b = this.overridelistener) === null || _b === void 0 ? void 0 : _b.toLowerCase()) !== "true")
                    super.connectedCallback();
                return;
            }
            let template = document.createElement("template");
            template.innerHTML = FullText.template;
            this.attachShadow({ mode: "open" });
            this.shadowRoot.append(template.content.cloneNode(true));
            this.textBox = this.shadowRoot.querySelector("#editor");
            if (this.value)
                this.textBox.innerText = this.value;
            this.editorSettings.target = this.textBox;
            if (!PopForums.userState.isImageEnabled || ((_c = this.forcenoimage) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === "true")
                this.editorSettings.toolbar = FullText.postNoImageToolbar;
            if (((_d = this.isshort) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === "true")
                this.editorSettings.height = "200";
            var self = this;
            this.editorSettings.setup = function (editor) {
                editor.on("init", function () {
                    this.on("focusout", function (e) {
                        editor.save();
                        self.value = self.textBox.value;
                        self.externalFormElement.value = self.value;
                    });
                    this.on("blur", function (e) {
                        editor.save();
                        self.value = self.textBox.value;
                        self.externalFormElement.value = self.value;
                    });
                    editor.save();
                    self.value = self.textBox.value;
                    self.externalFormElement.value = self.value;
                });
                function InstantImageUpload() {
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/jpeg,image/gif,image/png");
                    input.addEventListener("change", (e) => {
                        const file = input.files[0];
                        let url = "/Forums/Image/UploadPostImage";
                        let form = new FormData();
                        form.append("file", file);
                        editor.setProgressState(true);
                        fetch(url, {
                            method: "POST",
                            body: form
                        })
                            .then(response => {
                            if (response.ok)
                                return response.json();
                            alert("Could not upload image: " + response.status);
                        })
                            .then(payload => {
                            editor.insertContent(`<img src="${payload.url}" />`);
                            PopForums.userState.postImageIds.push(payload.id);
                        })
                            .catch(error => {
                            alert("Could not upload image: " + error);
                        })
                            .finally(() => {
                            editor.setProgressState(false);
                            input.remove();
                        });
                    });
                    input.style.display = "none";
                    document.getElementById("ForumContainer").append(input);
                    input.click();
                }
                ;
                editor.ui.registry.addButton("imageup", {
                    icon: "upload",
                    tooltip: "Upload Image",
                    onAction: () => InstantImageUpload()
                });
            };
            tinymce.init(this.editorSettings);
            this.externalFormElement = document.createElement("input");
            this.externalFormElement.id = this.formID;
            this.externalFormElement.setAttribute("name", this.formID);
            this.externalFormElement.type = "hidden";
            this.appendChild(this.externalFormElement);
            if (((_e = this.overridelistener) === null || _e === void 0 ? void 0 : _e.toLowerCase()) !== "true")
                super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "nextQuote"];
        }
        updateUI(data) {
            if (data !== null && data !== undefined) {
                if (PopForums.userState.isPlainText) {
                    this.externalFormElement.value += data;
                    this.value = this.externalFormElement.value;
                }
                else {
                    let editor = tinymce.get("editor");
                    var content = editor.getContent();
                    content += data;
                    editor.setContent(content);
                    this.textBox.value += content;
                    editor.save();
                    this.value = this.textBox.value;
                    this.externalFormElement.value = this.value;
                }
            }
        }
    }
    FullText.formAssociated = true;
    FullText.editorCSS = "/PopForums/lib/bootstrap/dist/css/bootstrap.min.css,/PopForums/lib/PopForums/dist/Editor.min.css";
    FullText.postNoImageToolbar = "cut copy paste | bold italic | bullist numlist blockquote removeformat | link";
    FullText.id = "FullText";
    FullText.template = `<textarea id="editor"></textarea>
    `;
    PopForums.FullText = FullText;
    customElements.define('pf-fulltext', FullText);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class HomeUpdater extends HTMLElement {
        constructor() {
            super();
        }
        async connectedCallback() {
            let service = await PopForums.MessagingService.GetService();
            let connection = service.connection;
            let self = this;
            connection.on("notifyForumUpdate", function (data) {
                self.updateForumStats(data);
            });
            await connection.invoke("listenToAllForums");
        }
        updateForumStats(data) {
            let row = document.querySelector("[data-forumid='" + data.forumID + "']");
            row.querySelector(".topicCount").innerHTML = data.topicCount;
            row.querySelector(".postCount").innerHTML = data.postCount;
            row.querySelector(".lastPostName").innerHTML = data.lastPostName;
            row.querySelector("pf-formattedtime").setAttribute("utctime", data.utc);
            row.querySelector(".newIndicator .icon").classList.remove("text-muted", "icon-file-earmark-text");
            row.querySelector(".newIndicator .icon").classList.add("text-warning", "icon-file-earmark-text-fill");
        }
        ;
    }
    PopForums.HomeUpdater = HomeUpdater;
    customElements.define('pf-homeupdater', HomeUpdater);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class LoginForm extends HTMLElement {
        constructor() {
            super();
        }
        get templateid() {
            return this.getAttribute("templateid");
        }
        get isExternalLogin() {
            return this.getAttribute("isexternallogin");
        }
        connectedCallback() {
            let template = document.getElementById(this.templateid);
            if (!template) {
                console.error(`Can't find templateID ${this.templateid} to make login form.`);
                return;
            }
            this.append(template.content.cloneNode(true));
            this.email = this.querySelector("#EmailLogin");
            this.password = this.querySelector("#PasswordLogin");
            this.button = this.querySelector("#LoginButton");
            this.button.addEventListener("click", () => {
                this.executeLogin();
            });
            this.querySelectorAll("#EmailLogin,#PasswordLogin").forEach(x => x.addEventListener("keydown", (e) => {
                if (e.code === "Enter")
                    this.executeLogin();
            }));
        }
        executeLogin() {
            let path = "/Identity/Login";
            if (this.isExternalLogin.toLowerCase() === "true")
                path = "/Identity/LoginAndAssociate";
            let payload = JSON.stringify({ email: this.email.value, password: this.password.value });
            fetch(PopForums.AreaPath + path, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .then(function (response) {
                return response.json();
            })
                .then(function (result) {
                switch (result.result) {
                    case true:
                        let destination = document.querySelector("#Referrer").value;
                        location.href = destination;
                        break;
                    default:
                        let loginResult = document.querySelector("#LoginResult");
                        loginResult.innerHTML = result.message;
                        loginResult.classList.remove("d-none");
                }
            })
                .catch(function (error) {
                let loginResult = document.querySelector("#LoginResult");
                loginResult.innerHTML = "There was an unknown error while attempting login";
                loginResult.classList.remove("d-none");
            });
        }
    }
    PopForums.LoginForm = LoginForm;
    customElements.define('pf-loginform', LoginForm);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class MorePostsBeforeReplyButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        connectedCallback() {
            var _a;
            this.innerHTML = MorePostsBeforeReplyButton.template;
            let button = this.querySelector("input");
            button.value = this.buttontext;
            if (((_a = this.buttonclass) === null || _a === void 0 ? void 0 : _a.length) > 0)
                this.buttonclass.split(" ").forEach((c) => button.classList.add(c));
            button.addEventListener("click", (e) => {
                PopForums.currentTopicState.loadMorePosts();
            });
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "isNewerPostsAvailable"];
        }
        updateUI(data) {
            let button = this.querySelector("input");
            if (!data)
                button.style.visibility = "hidden";
            else
                button.style.visibility = "visible";
        }
    }
    MorePostsBeforeReplyButton.template = `<input type="button" />`;
    PopForums.MorePostsBeforeReplyButton = MorePostsBeforeReplyButton;
    customElements.define('pf-morepostsbeforereplybutton', MorePostsBeforeReplyButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class MorePostsButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        connectedCallback() {
            var _a;
            this.innerHTML = MorePostsButton.template;
            let button = this.querySelector("input");
            button.value = this.buttontext;
            if (((_a = this.buttonclass) === null || _a === void 0 ? void 0 : _a.length) > 0)
                this.buttonclass.split(" ").forEach((c) => button.classList.add(c));
            button.addEventListener("click", (e) => {
                PopForums.currentTopicState.loadMorePosts();
            });
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "highPage"];
        }
        updateUI(data) {
            let button = this.querySelector("input");
            if (PopForums.currentTopicState.pageCount === 1 || data === PopForums.currentTopicState.pageCount)
                button.style.visibility = "hidden";
            else
                button.style.visibility = "visible";
        }
    }
    MorePostsButton.template = `<input type="button" />`;
    PopForums.MorePostsButton = MorePostsButton;
    customElements.define('pf-morepostsbutton', MorePostsButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class NotificationItem extends HTMLElement {
        constructor(notification) {
            super();
            this.notification = notification;
        }
        connectedCallback() {
            let markup;
            let link;
            switch (this.notification.notificationType) {
                case 3: // Award
                    markup = `${PopForums.localizations.award}: <b>${this.notification.data.title}</b>`;
                    link = "/Forums/Account/ViewProfile/" + this.notification.userID + "#Awards";
                    break;
                case 2: // QuestionAnswered
                    markup = PopForums.localizations.questionAnsweredNotification
                        .replace("{0}", this.notification.data.askerName)
                        .replace("{1}", this.notification.data.title);
                    link = "/Forums/PostLink/" + this.notification.data.postID;
                    break;
                case 0: // NewReply
                    markup = PopForums.localizations.newReplyNotification
                        .replace("{0}", this.notification.data.postName)
                        .replace("{1}", this.notification.data.title);
                    link = "/Forums/Forum/GoToNewestPost/" + this.notification.data.topicID;
                    break;
                case 1: // VoteUp
                    markup = PopForums.localizations.voteUpNotification
                        .replace("{0}", this.notification.data.voterName)
                        .replace("{1}", this.notification.data.title);
                    link = "/Forums/PostLink/" + this.notification.data.postID;
                    break;
                default:
                    console.log(`Unknown notification type: ${this.notification.notificationType}`);
            }
            let newness = " border border-2";
            if (!this.notification.isRead)
                newness = " text-bg-light border-primary border border-2";
            let template = `<div class="card mb-3${newness}">
    <div class="card-body">
        <p>${markup}</p>
    </div>
    <div class="card-footer text-end">
    </div>
    <a href="${link}" class="stretched-link"></a>
</div>`;
            this.innerHTML = template;
            let timeStamp = new PopForums.FormattedTime();
            timeStamp.setAttribute("utctime", this.notification.timeStamp.toString());
            let footer = this.querySelector(".card-footer");
            footer.append(timeStamp);
            this.querySelector("a").addEventListener("click", (e) => {
                PopForums.userState.MarkRead(this.notification.contextID, this.notification.notificationType);
            });
        }
        MarkRead() {
            let box = this.querySelector(".card");
            if (box) {
                box.classList.remove("text-bg-light", "border-primary");
            }
            this.notification.isRead = true;
        }
    }
    PopForums.NotificationItem = NotificationItem;
    customElements.define('pf-notificationitem', NotificationItem);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class NotificationList extends PopForums.ElementBase {
        constructor() {
            super();
        }
        connectedCallback() {
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.userState, "notifications"];
        }
        updateUI(data) {
            if (!data || data.length === 0) {
                this.replaceChildren();
                return;
            }
            data.forEach(item => {
                let n = new PopForums.NotificationItem(item);
                this.append(n);
            });
        }
    }
    PopForums.NotificationList = NotificationList;
    customElements.define('pf-notificationlist', NotificationList);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class NotificationMarkAllButton extends HTMLElement {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        connectedCallback() {
            this.innerHTML = `<input type="button" class="${this.buttonclass}" value="${this.buttontext}" data-bs-dismiss="offcanvas" />`;
            this.querySelector("input").addEventListener("click", () => {
                PopForums.userState.MarkAllRead();
            });
        }
    }
    PopForums.NotificationMarkAllButton = NotificationMarkAllButton;
    customElements.define('pf-notificationmarkallbutton', NotificationMarkAllButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class NotificationToggle extends PopForums.ElementBase {
        constructor() {
            super();
            this.userState = PopForums.userState;
            this.isInit = false;
        }
        get panelid() {
            return this.getAttribute("panelid");
        }
        get notificationlistid() {
            return this.getAttribute("notificationlistid");
        }
        connectedCallback() {
            const delegate = this.ready.bind(this);
            this.isReady = PopForums.LocalizationService.subscribe(delegate);
            if (this.isReady)
                this.ready();
            super.connectedCallback();
        }
        ready() {
            this.title = PopForums.localizations.notifications;
            this.panel = document.getElementById(this.panelid);
            this.offCanvas = new bootstrap.Offcanvas(this.panel);
            this.addEventListener("click", this.show);
            let list = document.getElementById(this.notificationlistid);
            this.userState.list = list;
        }
        async show() {
            this.offCanvas.show();
            this.panel.addEventListener("hide.bs.offcanvas", event => {
                this.userState.list.removeEventListener("scroll", this.userState.ScrollLoad);
            });
            await this.userState.LoadNotifications();
            this.userState.list.addEventListener("scroll", this.userState.ScrollLoad);
        }
        getDependentReference() {
            return [PopForums.userState, "notificationCount"];
        }
        updateUI(data) {
            if (data === 0)
                this.innerHTML = `<span class="icon icon-bell-fill"></span>`;
            else {
                this.innerHTML = `<span class="icon icon-bell-fill"></span><span class="badge ms-1">${data}</span>`;
                if (this.isInit) {
                    this.innerHTML = `<span class="icon icon-bell-fill"></span><span class="badge ms-1 explode">${data}</span>`;
                }
            }
            this.isInit = true;
        }
    }
    PopForums.NotificationToggle = NotificationToggle;
    customElements.define('pf-notificationtoggle', NotificationToggle);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PMCount extends PopForums.ElementBase {
        constructor() {
            super();
            this.isInit = false;
        }
        getDependentReference() {
            return [PopForums.userState, "newPmCount"];
        }
        updateUI(data) {
            if (data === 0)
                this.innerHTML = "";
            else {
                this.innerHTML = `<span class="badge">${data}</span>`;
                if (this.isInit)
                    this.innerHTML = `<span class="badge explode">${data}</span>`;
            }
            this.isInit = true;
        }
    }
    PopForums.PMCount = PMCount;
    customElements.define('pf-pmcount', PMCount);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PMForm extends HTMLElement {
        constructor() {
            super();
        }
        connectedCallback() {
            const delegate = this.ready.bind(this);
            this.isReady = PopForums.LocalizationService.subscribe(delegate);
            if (this.isReady)
                this.ready();
        }
        ready() {
            this.innerHTML = PMForm.template;
            let button = this.querySelector("button");
            button.innerHTML = PopForums.localizations.send;
            let textBox = this.querySelector("textarea");
            textBox.addEventListener("keydown", (e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    this.send(textBox);
                }
            });
            button.addEventListener("click", () => {
                this.send(textBox);
            });
        }
        send(textBox) {
            PopForums.currentPmState.send(textBox.value);
            textBox.value = "";
        }
    }
    PMForm.template = `<div class="input-group mb-3">
    <textarea class="form-control"></textarea>
    <button class="btn btn-primary" type="button"></button>
</div>`;
    PopForums.PMForm = PMForm;
    customElements.define('pf-pmform', PMForm);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PostMiniProfile extends HTMLElement {
        constructor() {
            super();
        }
        get username() {
            return this.getAttribute("username");
        }
        get usernameclass() {
            return this.getAttribute("usernameclass");
        }
        get userid() {
            return this.getAttribute("userid");
        }
        get miniProfileBoxClass() {
            return this.getAttribute("miniprofileboxclass");
        }
        connectedCallback() {
            this.isLoaded = false;
            this.innerHTML = PostMiniProfile.template;
            let nameHeader = this.querySelector("h3");
            this.usernameclass.split(" ").forEach((c) => nameHeader.classList.add(c));
            nameHeader.innerHTML = this.username;
            nameHeader.addEventListener("click", () => {
                this.toggle();
            });
            this.box = this.querySelector("div");
            this.miniProfileBoxClass.split(" ").forEach((c) => this.box.classList.add(c));
        }
        toggle() {
            if (!this.isLoaded) {
                fetch(PopForums.AreaPath + "/Account/MiniProfile/" + this.userid)
                    .then(response => response.text()
                    .then(text => {
                    let sub = this.box.querySelector("div");
                    sub.innerHTML = text;
                    const height = sub.getBoundingClientRect().height;
                    this.boxHeight = `${height}px`;
                    this.box.style.height = this.boxHeight;
                    this.isOpen = true;
                    this.isLoaded = true;
                }));
            }
            else if (!this.isOpen) {
                this.box.style.height = this.boxHeight;
                this.isOpen = true;
            }
            else {
                this.box.style.height = "0";
                this.isOpen = false;
            }
        }
    }
    PostMiniProfile.template = `<h3></h3>
<div>
    <div class="py-1 px-3 mb-2"></div>
</div>`;
    PopForums.PostMiniProfile = PostMiniProfile;
    customElements.define('pf-postminiprofile', PostMiniProfile);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PostModerationLogButton extends HTMLElement {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        get postid() {
            return this.getAttribute("postid");
        }
        get parentSelectorToAppendTo() {
            return this.getAttribute("parentselectortoappendto");
        }
        connectedCallback() {
            this.innerHTML = PostModerationLogButton.template;
            let button = this.querySelector("input");
            button.value = this.buttontext;
            let classes = this.buttonclass;
            if ((classes === null || classes === void 0 ? void 0 : classes.length) > 0)
                classes.split(" ").forEach((c) => button.classList.add(c));
            let self = this;
            let container;
            button.addEventListener("click", () => {
                if (!container) {
                    let parentContainer = self.closest(this.parentSelectorToAppendTo);
                    if (!parentContainer) {
                        console.error(`Can't find a parent selector "${this.parentSelectorToAppendTo}" to append post moderation log to.`);
                        return;
                    }
                    container = document.createElement("div");
                    container.classList.add("my-3");
                    parentContainer.appendChild(container);
                }
                if (container.style.display !== "block")
                    fetch(PopForums.AreaPath + "/Moderator/PostModerationLog/" + this.postid)
                        .then(response => response.text()
                        .then(text => {
                        container.innerHTML = text;
                        container.style.display = "block";
                    }));
                else
                    container.style.display = "none";
            });
        }
    }
    PostModerationLogButton.template = `<input type="button" />`;
    PopForums.PostModerationLogButton = PostModerationLogButton;
    customElements.define("pf-postmoderationlogbutton", PostModerationLogButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PreviewButton extends HTMLElement {
        constructor() {
            super();
        }
        get labelText() {
            return this.getAttribute("labeltext");
        }
        get textSourceSelector() {
            return this.getAttribute("textsourceselector");
        }
        get isPlainTextSelector() {
            return this.getAttribute("isplaintextselector");
        }
        connectedCallback() {
            this.innerHTML = PreviewButton.template;
            let button = this.querySelector("input");
            button.value = this.labelText;
            let headText = this.querySelector("h4");
            headText.innerText = this.labelText;
            var modal = this.querySelector(".modal");
            modal.addEventListener("shown.bs.modal", () => {
                this.openModal();
            });
        }
        openModal() {
            tinymce.triggerSave();
            let fullText = document.querySelector(this.textSourceSelector);
            let model = {
                FullText: fullText.value,
                IsPlainText: document.querySelector(this.isPlainTextSelector).value.toLowerCase() === "true"
            };
            fetch(PopForums.AreaPath + "/Forum/PreviewText", {
                method: "POST",
                body: JSON.stringify(model),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.text()
                .then(text => {
                let r = this.querySelector(".parsedFullText");
                r.innerHTML = text;
            }));
        }
    }
    PreviewButton.template = `<input type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#PreviewModal">
<div class="modal fade" id="PreviewModal" tabindex="-1" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title"></h4>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="postItem parsedFullText"></div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>`;
    PopForums.PreviewButton = PreviewButton;
    customElements.define('pf-previewbutton', PreviewButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PreviousPostsButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        connectedCallback() {
            var _a;
            this.innerHTML = PreviousPostsButton.template;
            let button = this.querySelector("input");
            button.value = this.buttontext;
            if (((_a = this.buttonclass) === null || _a === void 0 ? void 0 : _a.length) > 0)
                this.buttonclass.split(" ").forEach((c) => button.classList.add(c));
            button.addEventListener("click", (e) => {
                PopForums.currentTopicState.loadPreviousPosts();
            });
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "lowPage"];
        }
        updateUI(data) {
            let button = this.querySelector("input");
            if (PopForums.currentTopicState.pageCount === 1 || data === 1)
                button.style.visibility = "hidden";
            else
                button.style.visibility = "visible";
        }
    }
    PreviousPostsButton.template = `<input type="button" />`;
    PopForums.PreviousPostsButton = PreviousPostsButton;
    customElements.define('pf-previouspostsbutton', PreviousPostsButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class QuoteButton extends HTMLElement {
        constructor() {
            super();
        }
        get name() {
            return this.getAttribute("name");
        }
        get containerid() {
            return this.getAttribute("containerid");
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        get tip() {
            return this.getAttribute("tip");
        }
        get postID() {
            return this.getAttribute("postid");
        }
        connectedCallback() {
            let targetText = document.getElementById(this.containerid);
            this.innerHTML = QuoteButton.template;
            let button = this.querySelector("button");
            button.title = this.tip;
            ["mousedown", "touchstart"].forEach((e) => targetText.addEventListener(e, () => { if (this._tip)
                this._tip.hide(); }));
            button.value = this.buttontext;
            let classes = this.buttonclass;
            if ((classes === null || classes === void 0 ? void 0 : classes.length) > 0)
                classes.split(" ").forEach((c) => button.classList.add(c));
            this.onclick = (e) => {
                // get this from topic state's callback/ready method, because iOS loses selection when you touch quote button
                let fragment = PopForums.currentTopicState.documentFragment;
                let ancestor = PopForums.currentTopicState.selectionAncestor;
                if (!fragment) {
                    let selection = document.getSelection();
                    if (!selection || selection.rangeCount === 0 || selection.getRangeAt(0).toString().length === 0) {
                        // prompt to select
                        this._tip = new bootstrap.Tooltip(button, { trigger: "manual" });
                        this._tip.show();
                        return;
                    }
                    let range = selection.getRangeAt(0);
                    ancestor = range.commonAncestorContainer;
                    fragment = range.cloneContents();
                }
                let div = document.createElement("div");
                div.appendChild(fragment);
                // is selection in the container?
                while (ancestor.id !== this.containerid && ancestor.parentElement !== null) {
                    ancestor = ancestor.parentElement;
                }
                let isInText = ancestor.id === this.containerid;
                // if not, is it partially in the container?
                if (!isInText) {
                    let container = div.querySelector("#" + this.containerid);
                    if (container !== null && container !== undefined) {
                        // it's partially in the container, so just get that part
                        div.innerHTML = container.innerHTML;
                        isInText = true;
                    }
                }
                if (isInText) {
                    // activate or add to quote
                    let result;
                    if (PopForums.userState.isPlainText)
                        result = `[quote][i]${this.name}:[/i]\r\n ${div.innerText}[/quote]`;
                    else
                        result = `<blockquote><p><i>${this.name}:</i></p>${div.innerHTML}</blockquote><p></p>`;
                    PopForums.currentTopicState.nextQuote = result;
                    if (!PopForums.currentTopicState.isReplyLoaded)
                        PopForums.currentTopicState.loadReply(PopForums.currentTopicState.topicID, Number(this.postID), true);
                }
                let temp = document.getSelection();
                if (temp)
                    temp.removeAllRanges();
            };
        }
    }
    QuoteButton.template = `<button type="button" data-bs-toggle="tooltip" title="" />`;
    PopForums.QuoteButton = QuoteButton;
    customElements.define('pf-quotebutton', QuoteButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class ReplyButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        get topicid() {
            return this.getAttribute("topicid");
        }
        get postid() {
            return this.getAttribute("postid");
        }
        get overridedisplay() {
            return this.getAttribute("overridedisplay");
        }
        connectedCallback() {
            var _a;
            this.innerHTML = ReplyButton.template;
            let button = this.querySelector("button");
            button.title = this.buttontext;
            if (((_a = this.buttonclass) === null || _a === void 0 ? void 0 : _a.length) > 0)
                this.buttonclass.split(" ").forEach((c) => button.classList.add(c));
            if (button.classList.contains("btn"))
                button.innerText = this.buttontext;
            button.addEventListener("click", (e) => {
                PopForums.currentTopicState.loadReply(Number(this.topicid), Number(this.postid), true);
            });
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "isReplyLoaded"];
        }
        updateUI(data) {
            var _a;
            if (((_a = this.overridedisplay) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "true")
                return;
            let button = this.querySelector(":first-child");
            if (data)
                button.style.display = "none";
            else
                button.style.display = "initial";
        }
    }
    ReplyButton.template = `<button type="button"></button>`;
    PopForums.ReplyButton = ReplyButton;
    customElements.define('pf-replybutton', ReplyButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class ReplyForm extends HTMLElement {
        constructor() {
            super();
        }
        get templateID() {
            return this.getAttribute("templateid");
        }
        connectedCallback() {
            let template = document.getElementById(this.templateID);
            if (!template) {
                console.error(`Can't find templateID ${this.templateID} to make reply form.`);
                return;
            }
            this.append(template.content.cloneNode(true));
            this.button = this.querySelector("#SubmitReply");
            this.button.addEventListener("click", () => {
                this.submitReply();
            });
        }
        submitReply() {
            this.button.setAttribute("disabled", "disabled");
            let closeCheck = document.querySelector("#CloseOnReply");
            let closeOnReply = false;
            if (closeCheck && closeCheck.checked)
                closeOnReply = true;
            let postImageIDs = PopForums.userState.postImageIds;
            let model = {
                Title: this.querySelector("#NewReply #Title").value,
                FullText: this.querySelector("#NewReply #FullText").value,
                IncludeSignature: this.querySelector("#NewReply #IncludeSignature").checked,
                ItemID: this.querySelector("#NewReply #ItemID").value,
                CloseOnReply: closeOnReply,
                IsPlainText: this.querySelector("#NewReply #IsPlainText").value.toLowerCase() === "true",
                ParentPostID: this.querySelector("#NewReply #ParentPostID").value,
                PostImageIDs: postImageIDs
            };
            fetch(PopForums.AreaPath + "/Forum/PostReply", {
                method: "POST",
                body: JSON.stringify(model),
                headers: {
                    "Content-Type": "application/json"
                },
            })
                .then(response => response.json())
                .then(result => {
                switch (result.result) {
                    case true:
                        window.location = result.redirect;
                        break;
                    default:
                        var r = this.querySelector("#PostResponseMessage");
                        r.innerHTML = result.message;
                        this.button.removeAttribute("disabled");
                        r.style.display = "block";
                }
            })
                .catch(error => {
                var r = this.querySelector("#PostResponseMessage");
                r.innerHTML = "There was an unknown error while trying to post";
                this.button.removeAttribute("disabled");
                r.style.display = "block";
            });
        }
        ;
    }
    PopForums.ReplyForm = ReplyForm;
    customElements.define('pf-replyform', ReplyForm);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class SearchNavForm extends HTMLElement {
        constructor() {
            super();
        }
        get templateid() {
            return this.getAttribute("templateid");
        }
        get textboxid() {
            return this.getAttribute("textboxid");
        }
        get dropdownid() {
            return this.getAttribute("dropdownid");
        }
        connectedCallback() {
            let template = document.getElementById(this.templateid);
            if (!template) {
                console.error(`Can't find templateID ${this.templateid} to make search form.`);
                return;
            }
            this.append(template.content.cloneNode(true));
            this.searchBox = this.querySelector("#" + this.textboxid);
            this.dropdown = this.querySelector("#" + this.dropdownid);
            this.dropdown.addEventListener("shown.bs.dropdown", () => {
                this.searchBox.focus();
            });
        }
    }
    PopForums.SearchNavForm = SearchNavForm;
    customElements.define('pf-searchnavform', SearchNavForm);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class SubscribeButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get subscribetext() {
            return this.getAttribute("subscribetext");
        }
        get unsubscribetext() {
            return this.getAttribute("unsubscribetext");
        }
        connectedCallback() {
            this.innerHTML = SubscribeButton.template;
            let button = this.querySelector("button");
            this.buttonclass.split(" ").forEach((c) => button.classList.add(c));
            button.addEventListener("click", () => {
                fetch(PopForums.AreaPath + "/Subscription/ToggleSubscription/" + PopForums.currentTopicState.topicID, {
                    method: "POST"
                })
                    .then(response => response.json())
                    .then(result => {
                    switch (result.data.isSubscribed) {
                        case true:
                            PopForums.currentTopicState.isSubscribed = true;
                            break;
                        case false:
                            PopForums.currentTopicState.isSubscribed = false;
                            break;
                        default:
                        // TODO: something else
                    }
                })
                    .catch(() => {
                    // TODO: handle error
                });
            });
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentTopicState, "isSubscribed"];
        }
        updateUI(data) {
            let button = this.querySelector("button");
            if (data) {
                button.title = this.unsubscribetext;
                button.classList.remove("icon-bell-slash", "text-muted");
                button.classList.add("icon-bell-fill", "text-warning");
            }
            else {
                button.title = this.subscribetext;
                button.classList.remove("icon-bell-fill", "text-warning");
                button.classList.add("icon-bell-slash", "text-muted");
            }
        }
    }
    SubscribeButton.template = `<button type="button" class="btn-link icon"></button>`;
    PopForums.SubscribeButton = SubscribeButton;
    customElements.define('pf-subscribebutton', SubscribeButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class TopicButton extends PopForums.ElementBase {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        get forumid() {
            return this.getAttribute("forumid");
        }
        connectedCallback() {
            var _a;
            this.innerHTML = TopicButton.template;
            let button = this.querySelector("input");
            button.value = this.buttontext;
            if (((_a = this.buttonclass) === null || _a === void 0 ? void 0 : _a.length) > 0)
                this.buttonclass.split(" ").forEach((c) => button.classList.add(c));
            button.addEventListener("click", () => {
                PopForums.currentForumState.loadNewTopic();
            });
            super.connectedCallback();
        }
        getDependentReference() {
            return [PopForums.currentForumState, "isNewTopicLoaded"];
        }
        updateUI(data) {
            if (data)
                this.style.display = "none";
            else
                this.style.display = "initial";
        }
    }
    TopicButton.template = `<input type="button" />`;
    PopForums.TopicButton = TopicButton;
    customElements.define('pf-topicbutton', TopicButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class TopicForm extends HTMLElement {
        constructor() {
            super();
        }
        get templateID() {
            return this.getAttribute("templateid");
        }
        connectedCallback() {
            let template = document.getElementById(this.templateID);
            if (!template) {
                console.error(`Can't find templateID ${this.templateID} to make reply form.`);
                return;
            }
            this.append(template.content.cloneNode(true));
            this.button = this.querySelector("#SubmitNewTopic");
            this.button.addEventListener("click", () => {
                this.submitTopic();
            });
        }
        submitTopic() {
            this.button.setAttribute("disabled", "disabled");
            let postImageIDs = PopForums.userState.postImageIds;
            let model = {
                Title: this.querySelector("#NewTopic #Title").value,
                FullText: this.querySelector("#NewTopic #FullText").value,
                IncludeSignature: this.querySelector("#NewTopic #IncludeSignature").checked,
                ItemID: this.querySelector("#NewTopic #ItemID").value,
                IsPlainText: this.querySelector("#NewTopic #IsPlainText").value.toLowerCase() === "true",
                PostImageIDs: postImageIDs
            };
            fetch(PopForums.AreaPath + "/Forum/PostTopic", {
                method: "POST",
                body: JSON.stringify(model),
                headers: {
                    "Content-Type": "application/json"
                },
            })
                .then(response => response.json())
                .then(result => {
                switch (result.result) {
                    case true:
                        window.location = result.redirect;
                        break;
                    default:
                        var r = this.querySelector("#PostResponseMessage");
                        r.innerHTML = result.message;
                        this.button.removeAttribute("disabled");
                        r.style.display = "block";
                }
            })
                .catch(error => {
                var r = this.querySelector("#PostResponseMessage");
                r.innerHTML = "There was an unknown error while trying to post";
                this.button.removeAttribute("disabled");
                r.style.display = "block";
            });
        }
        ;
    }
    PopForums.TopicForm = TopicForm;
    customElements.define('pf-topicform', TopicForm);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class TopicModerationLogButton extends HTMLElement {
        constructor() {
            super();
        }
        get buttonclass() {
            return this.getAttribute("buttonclass");
        }
        get buttontext() {
            return this.getAttribute("buttontext");
        }
        get topicid() {
            return this.getAttribute("topicid");
        }
        connectedCallback() {
            this.innerHTML = TopicModerationLogButton.template;
            let button = this.querySelector("input");
            button.value = this.buttontext;
            let classes = this.buttonclass;
            if ((classes === null || classes === void 0 ? void 0 : classes.length) > 0)
                classes.split(" ").forEach((c) => button.classList.add(c));
            button.addEventListener("click", () => {
                let container = this.querySelector("div");
                if (container.style.display !== "block")
                    fetch(PopForums.AreaPath + "/Moderator/TopicModerationLog/" + this.topicid)
                        .then(response => response.text()
                        .then(text => {
                        container.innerHTML = text;
                        container.style.display = "block";
                    }));
                else
                    container.style.display = "none";
            });
        }
    }
    TopicModerationLogButton.template = `<input type="button" class="my-2" />
    <div></div>`;
    PopForums.TopicModerationLogButton = TopicModerationLogButton;
    customElements.define("pf-topicmoderationlogbutton", TopicModerationLogButton);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class VoteCount extends HTMLElement {
        constructor() {
            super();
        }
        get votes() {
            return this.getAttribute("votes");
        }
        set votes(value) {
            this.setAttribute("votes", value);
        }
        get postid() {
            return this.getAttribute("postid");
        }
        get containerclass() {
            return this.getAttribute("containerclass");
        }
        get votescontainerclass() {
            return this.getAttribute("votescontainerclass");
        }
        get badgeclass() {
            return this.getAttribute("badgeclass");
        }
        get votebuttonclass() {
            return this.getAttribute("votebuttonclass");
        }
        get isloggedin() {
            return this.getAttribute("isloggedin").toLowerCase();
        }
        get isauthor() {
            return this.getAttribute("isauthor").toLowerCase();
        }
        get isvoted() {
            return this.getAttribute("isvoted").toLowerCase();
        }
        connectedCallback() {
            var _a, _b, _c;
            this.innerHTML = VoteCount.template;
            let topContainer = this.querySelector("div");
            if (((_a = this.containerclass) === null || _a === void 0 ? void 0 : _a.length) > 0)
                this.containerclass.split(" ").forEach((c) => topContainer.classList.add(c));
            this.badge = this.querySelector("div > div");
            this.badge.innerHTML = "+" + this.votes;
            if (((_b = this.badgeclass) === null || _b === void 0 ? void 0 : _b.length) > 0)
                this.badgeclass.split(" ").forEach((c) => this.badge.classList.add(c));
            let statusHtml = this.buttonGenerator();
            if (statusHtml != "") {
                let status = document.createElement("template");
                status.innerHTML = this.buttonGenerator();
                this.firstElementChild.append(status.content.firstChild);
            }
            let voteButton = this.querySelector("span");
            if (voteButton) {
                if (((_c = this.votebuttonclass) === null || _c === void 0 ? void 0 : _c.length) > 0)
                    this.votebuttonclass.split(" ").forEach((c) => voteButton.classList.add(c));
                voteButton.addEventListener("click", () => {
                    voteButton.classList.remove("icon-plus-square", "icon-plus-square-fill");
                    voteButton.classList.add("spinner-border", "spinner-border-sm");
                    fetch(PopForums.AreaPath + "/Forum/ToggleVote/" + this.postid, { method: "POST" })
                        .then(response => response.json()
                        .then((result) => {
                        this.votes = result.votes.toString();
                        this.badge.innerHTML = "+" + this.votes;
                        if (result.isVoted) {
                            voteButton.classList.remove("spinner-border", "spinner-border-sm");
                            voteButton.classList.add("icon-plus-square-fill");
                        }
                        else {
                            voteButton.classList.remove("spinner-border", "spinner-border-sm");
                            voteButton.classList.add("icon-plus-square");
                        }
                        this.applyPopover();
                    }));
                });
            }
            this.setupVoterPopover();
            this.applyPopover();
        }
        setupVoterPopover() {
            var _a;
            this.voterContainer = document.createElement("div");
            if (((_a = this.votescontainerclass) === null || _a === void 0 ? void 0 : _a.length) > 0)
                this.votescontainerclass.split(" ").forEach((c) => this.voterContainer.classList.add(c));
            this.voterContainer.innerHTML = `<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>`;
            this.popOver = new bootstrap.Popover(this.badge, {
                content: this.voterContainer,
                html: true,
                trigger: "click focus"
            });
            this.popoverEventHander = (e) => {
                fetch(PopForums.AreaPath + "/Forum/Voters/" + this.postid)
                    .then(response => response.text()
                    .then(text => {
                    let t = document.createElement("template");
                    t.innerHTML = text.trim();
                    this.voterContainer.innerHTML = "";
                    this.voterContainer.appendChild(t.content.firstChild);
                }));
            };
            this.badge.addEventListener("shown.bs.popover", this.popoverEventHander);
        }
        applyPopover() {
            if (this.votes === "0") {
                this.badge.style.cursor = "default";
                this.popOver.disable();
            }
            else {
                this.badge.style.cursor = "pointer";
                this.popOver.enable();
            }
        }
        buttonGenerator() {
            if (this.isloggedin === "false" || this.isauthor === "true")
                return "";
            if (this.isvoted === "true")
                return VoteCount.cancelVoteButton;
            return VoteCount.voteUpButton;
        }
    }
    VoteCount.template = `<div><div></div></div>`;
    VoteCount.voteUpButton = "<span class=\"icon icon-plus-square\"></span>";
    VoteCount.cancelVoteButton = "<span class=\"icon icon-plus-square-fill\"></span>";
    PopForums.VoteCount = VoteCount;
    customElements.define("pf-votecount", VoteCount);
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class Notification {
    }
    PopForums.Notification = Notification;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PrivateMessage {
    }
    PopForums.PrivateMessage = PrivateMessage;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PrivateMessageUser {
    }
    PopForums.PrivateMessageUser = PrivateMessageUser;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class LocalizationService {
        static init() {
            const path = PopForums.AreaPath + "/Resources";
            fetch(path)
                .then(response => {
                return response.json();
            })
                .then(json => {
                PopForums.localizations = Object.assign(new PopForums.Localizations(), json);
                return this.signal();
            });
        }
        static signal() {
            PopForums.Ready(() => {
                if (this.readies) {
                    for (let i of this.readies) {
                        i();
                    }
                }
                this.isSignaled = true;
            });
        }
        static subscribe(ready) {
            if (!this.readies)
                this.readies = new Array();
            this.readies.push(ready);
            return this.isSignaled;
        }
    }
    LocalizationService.isSignaled = false;
    PopForums.LocalizationService = LocalizationService;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class MessagingService {
        static async GetService() {
            if (!this.promise) {
                const service = new MessagingService();
                this.promise = service.start();
                this.service = service;
            }
            await Promise.all([this.promise]);
            return this.service;
        }
        async start() {
            this.connection = new signalR.HubConnectionBuilder().withUrl("/PopForumsHub").withAutomaticReconnect().build();
            await this.connection.start();
        }
    }
    PopForums.MessagingService = MessagingService;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class NotificationService {
        constructor(userState) {
            this.userState = userState;
        }
        async initialize() {
            let self = this;
            let service = await PopForums.MessagingService.GetService();
            this.connection = service.connection;
            this.connection.on("updatePMCount", function (pmCount) {
                self.userState.newPmCount = pmCount;
            });
            this.connection.on("notify", function (data) {
                let notification = Object.assign(new PopForums.Notification(), data);
                let list = self.userState.list.querySelectorAll("pf-notificationitem");
                list.forEach(item => {
                    let nitem = item.notification;
                    if (nitem.contextID === notification.contextID && nitem.notificationType === notification.notificationType) {
                        item.remove();
                    }
                });
                self.userState.notificationCount = notification.unreadCount;
                self.userState.notifications.unshift(notification);
            });
            this.connection.onreconnected(async () => {
                let notificationCount = await this.connection.invoke("GetNotificationCount");
                self.userState.notificationCount = notificationCount;
                let pmCount = await this.connection.invoke("GetPMCount");
                self.userState.newPmCount = pmCount;
            });
        }
        async LoadNotifications() {
            const json = await this.getNotifications();
            let a = new Array();
            let isEnd = true;
            json.forEach((item) => {
                let n = Object.assign(new PopForums.Notification(), item);
                a.push(n);
                this.userState.lastNotificationDate = n.timeStamp;
                isEnd = false;
            });
            this.userState.isNotificationEnd = isEnd;
            if (!isEnd)
                this.userState.notifications = a;
        }
        async MarkRead(contextID, notificationType) {
            await this.connection.invoke("MarkNotificationRead", contextID, notificationType);
        }
        async MarkAllRead() {
            await this.connection.send("MarkAllRead");
            let list = this.userState.list.querySelectorAll("pf-notificationitem");
            list.forEach(item => {
                item.MarkRead();
            });
            this.userState.notificationCount = 0;
        }
        async getNotifications() {
            const response = await this.connection.invoke("GetNotifications", this.userState.lastNotificationDate);
            return response;
        }
    }
    PopForums.NotificationService = NotificationService;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class ForumState extends PopForums.StateBase {
        constructor() {
            super();
            this.populateTopicRow = function (data) {
                let row = document.querySelector("#TopicTemplate").cloneNode(true);
                row.setAttribute("data-topicid", data.topicID);
                row.removeAttribute("id");
                row.querySelector(".startedByName").innerHTML = data.startedByName;
                row.querySelector(".indicatorLink").setAttribute("href", data.link);
                row.querySelector(".titleLink").innerHTML = data.title;
                row.querySelector(".titleLink").setAttribute("href", data.link);
                var forumTitle = row.querySelector(".forumTitle");
                if (forumTitle)
                    forumTitle.innerHTML = data.forumTitle;
                row.querySelector(".viewCount").innerHTML = data.viewCount;
                row.querySelector(".replyCount").innerHTML = data.replyCount;
                row.querySelector(".lastPostName").innerHTML = data.lastPostName;
                row.querySelector("pf-formattedtime").setAttribute("utctime", data.utc);
                return row;
            };
        }
        setupForum() {
            PopForums.Ready(async () => {
                this.isNewTopicLoaded = false;
                await this.forumListen();
            });
        }
        loadNewTopic() {
            fetch(PopForums.AreaPath + "/Forum/PostTopic/" + this.forumID)
                .then((response) => {
                return response.text();
            })
                .then((body) => {
                var n = document.querySelector("#NewTopic");
                if (!n)
                    throw ("Can't find a #NewTopic element to load in the new topic form.");
                n.innerHTML = body;
                n.style.display = "block";
                this.isNewTopicLoaded = true;
            });
        }
        async forumListen() {
            let service = await PopForums.MessagingService.GetService();
            let connection = service.connection;
            let self = this;
            connection.on("notifyUpdatedTopic", function (data) {
                let removal = document.querySelector('#TopicList tr[data-topicID="' + data.topicID + '"]');
                if (removal) {
                    removal.remove();
                }
                else {
                    let rows = document.querySelectorAll("#TopicList tr:not(#TopicTemplate)");
                    if (rows.length == self.pageSize)
                        rows[rows.length - 1].remove();
                }
                let row = self.populateTopicRow(data);
                row.classList.remove("hidden");
                document.querySelector("#TopicList tbody").prepend(row);
            });
            await connection.invoke("listenToForum", self.forumID);
        }
        async recentListen() {
            let service = await PopForums.MessagingService.GetService();
            let connection = service.connection;
            let self = this;
            connection.on("notifyRecentUpdate", function (data) {
                var removal = document.querySelector('#TopicList tr[data-topicID="' + data.topicID + '"]');
                if (removal) {
                    removal.remove();
                }
                else {
                    var rows = document.querySelectorAll("#TopicList tr:not(#TopicTemplate)");
                    if (rows.length == self.pageSize)
                        rows[rows.length - 1].remove();
                }
                var row = self.populateTopicRow(data);
                row.classList.remove("hidden");
                document.querySelector("#TopicList tbody").prepend(row);
            });
            connection.invoke("listenRecent");
        }
    }
    __decorate([
        PopForums.WatchProperty
    ], ForumState.prototype, "isNewTopicLoaded", void 0);
    PopForums.ForumState = ForumState;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class Localizations {
    }
    PopForums.Localizations = Localizations;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class PrivateMessageState extends PopForums.StateBase {
        constructor() {
            super();
            this.ScrollLoad = async () => {
                await this.LoadCheck();
            };
            this.scrollToElement = (id) => {
                let e = document.getElementById(id);
                e.scrollIntoView();
            };
            this.isStart = false;
        }
        setupPm() {
            PopForums.Ready(async () => {
                this.postStream = document.getElementById("PostStream");
                this.messages.forEach(x => {
                    let messageRow = this.populateMessage(x);
                    this.postStream.append(messageRow);
                });
                let service = await PopForums.MessagingService.GetService();
                this.connection = service.connection;
                let self = this;
                this.connection.on("addMessage", function (message) {
                    let messageRow = self.populateMessage(message);
                    let parent = self.postStream.parentElement;
                    let isBottom = parent.scrollHeight - parent.scrollTop - parent.clientHeight < 200;
                    self.postStream.append(messageRow);
                    if (isBottom)
                        parent.scrollTop = parent.scrollHeight;
                    self.ackRead();
                });
                this.connection.onreconnected(async () => {
                    let latestPostTime = this.messages[this.messages.length - 1].pmPostID;
                    const posts = await this.connection.invoke("GetMostRecentPmPosts", this.pmID, latestPostTime);
                    posts.reverse().forEach((item) => {
                        let m = this.populateMessage(item);
                        this.postStream.append(m);
                    });
                });
                await this.connection.invoke("listenToPm", this.pmID);
                if (this.newestPostID) {
                    this.scrollToElement("p" + this.newestPostID);
                }
                else {
                    this.postStream.parentElement.scrollTop = this.postStream.parentElement.scrollHeight;
                }
                await this.LoadCheck();
                this.postStream.parentElement.addEventListener("scroll", this.ScrollLoad);
            });
        }
        async LoadCheck() {
            let box = this.postStream.parentElement;
            if (!this.isStart && box.scrollTop < 250) {
                const posts = await this.GetPosts();
                let isStart = true;
                posts.reverse().forEach((item) => {
                    this.messages.unshift(item);
                    let m = this.populateMessage(item);
                    this.postStream.prepend(m);
                    isStart = false;
                });
                this.isStart = isStart;
            }
        }
        async GetPosts() {
            let earliestPostTime = this.messages[0].postTime;
            const response = await this.connection.invoke("GetPmPosts", this.pmID, earliestPostTime);
            return response;
        }
        send(fullText) {
            if (!fullText || fullText.trim().length === 0)
                return;
            this.connection.invoke("sendPm", this.pmID, fullText);
        }
        ackRead() {
            this.connection.invoke("ackReadPm", this.pmID);
        }
        populateMessage(data) {
            let template = document.createElement("template");
            template.innerHTML = PrivateMessageState.template;
            let messageRow = template.content.cloneNode(true);
            let body = messageRow.querySelector("div > div");
            body.innerHTML = data.fullText;
            if (data.userID === PopForums.userState.userID) {
                body.classList.add("alert-secondary");
                messageRow.querySelector("div").classList.add("ms-auto");
            }
            else
                body.classList.add("alert-primary");
            let timeStamp = messageRow.querySelector("pf-formattedtime");
            timeStamp.setAttribute("utctime", data.postTime.toString());
            let name = messageRow.querySelector(".messageName");
            name.innerHTML = data.name;
            body.parentElement.id = "p" + data.pmPostID;
            return messageRow;
        }
        ;
    }
    PrivateMessageState.template = `<div class="w-75 mb-3">
    <span class="d-flex">
        <small class="messageName me-3"></small>
        <small class="ms-auto"><pf-formattedtime utctime=""></pf-formattedtime></small>
    </span>
    <div class="alert">

    </div>
</div>`;
    PopForums.PrivateMessageState = PrivateMessageState;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class TopicState extends PopForums.StateBase {
        constructor() {
            super();
            this.loadingPosts = false;
            this.isScrollAdjusted = false;
            // this is intended to be called when the reply box is open
            this.setMorePostsAvailable = (newestPostIDonServer) => {
                this.isNewerPostsAvailable = newestPostIDonServer !== this.lastVisiblePostID;
            };
            this.loadMorePosts = () => {
                let topicPagePath;
                if (this.highPage === this.pageCount) {
                    topicPagePath = PopForums.AreaPath + "/Forum/TopicPartial/" + this.topicID + "?lastPost=" + this.lastVisiblePostID + "&lowPage=" + this.lowPage;
                }
                else {
                    this.highPage++;
                    topicPagePath = PopForums.AreaPath + "/Forum/TopicPage/" + this.topicID + "?pageNumber=" + this.highPage + "&low=" + this.lowPage + "&high=" + this.highPage;
                }
                fetch(topicPagePath)
                    .then(response => response.text()
                    .then(text => {
                    let t = document.createElement("template");
                    t.innerHTML = text.trim();
                    let stuff = t.content.firstChild;
                    let links = stuff.querySelector(".pagerLinks");
                    if (links)
                        stuff.removeChild(links);
                    let lastPostID = stuff.querySelector(".lastPostID");
                    stuff.removeChild(lastPostID);
                    let newPageCount = stuff.querySelector(".pageCount");
                    stuff.removeChild(newPageCount);
                    this.lastVisiblePostID = Number(lastPostID.value);
                    this.pageCount = Number(newPageCount.value);
                    let postStream = document.querySelector("#PostStream");
                    postStream.append(stuff);
                    document.querySelectorAll(".pagerLinks").forEach(x => x.replaceWith(links.cloneNode(true)));
                    document.querySelectorAll(".postItem img:not(.avatar)").forEach(x => x.classList.add("postImage"));
                    if (this.highPage == this.pageCount && this.lowPage == 1) {
                        document.querySelectorAll(".pagerLinks").forEach(x => x.remove());
                    }
                    this.loadingPosts = false;
                    if (!this.isScrollAdjusted) {
                        this.scrollToPostFromHash();
                    }
                    if (this.isReplyLoaded) {
                        let self = this;
                        this.connection.invoke("getLastPostID", this.topicID)
                            .then(function (result) {
                            self.setMorePostsAvailable(result);
                        });
                    }
                }));
            };
            this.loadPreviousPosts = () => {
                this.lowPage--;
                let topicPagePath = PopForums.AreaPath + "/Forum/TopicPage/" + this.topicID + "?pageNumber=" + this.lowPage + "&low=" + this.lowPage + "&high=" + this.highPage;
                fetch(topicPagePath)
                    .then(response => response.text()
                    .then(text => {
                    let t = document.createElement("template");
                    t.innerHTML = text.trim();
                    var stuff = t.content.firstChild;
                    var links = stuff.querySelector(".pagerLinks");
                    stuff.removeChild(links);
                    var postStream = document.querySelector("#PostStream");
                    postStream.prepend(stuff);
                    document.querySelectorAll(".pagerLinks").forEach(x => x.replaceWith(links.cloneNode(true)));
                    document.querySelectorAll(".postItem img:not(.avatar)").forEach(x => x.classList.add("postImage"));
                    if (this.highPage == this.pageCount && this.lowPage == 1) {
                        document.querySelectorAll(".pagerLinks").forEach(x => x.remove());
                    }
                }));
            };
            this.scrollLoad = () => {
                let streamEnd = document.querySelector("#StreamBottom");
                if (!streamEnd)
                    return; // this is a QA topic, no continuous post stream
                let top = streamEnd.offsetTop;
                let viewEnd = window.scrollY + window.outerHeight;
                let distance = top - viewEnd;
                if (!this.loadingPosts && distance < 250 && this.highPage < this.pageCount) {
                    this.loadingPosts = true;
                    this.loadMorePosts();
                }
            };
            this.scrollToElement = (id) => {
                let e = document.getElementById(id);
                let t = 0;
                if (e.offsetParent) {
                    while (e.offsetParent) {
                        t += e.offsetTop;
                        e = e.offsetParent;
                    }
                }
                else if (e.getBoundingClientRect().y) {
                    t += e.getBoundingClientRect().y;
                }
                let crumb = document.querySelector("#TopBreadcrumb");
                if (crumb)
                    t -= crumb.offsetHeight;
                scrollTo(0, t);
            };
            this.scrollToPostFromHash = () => {
                if (window.location.hash) {
                    Promise.all(Array.from(document.querySelectorAll("#PostStream img"))
                        .filter(img => !img.complete)
                        .map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
                        .then(() => {
                        let hash = window.location.hash;
                        while (hash.charAt(0) === '#')
                            hash = hash.substring(1);
                        let tag = document.querySelector("div[data-postID='" + hash + "']");
                        if (tag) {
                            let tagPosition = tag.getBoundingClientRect().top;
                            let crumb = document.querySelector("#ForumContainer #TopBreadcrumb");
                            let crumbHeight = crumb.getBoundingClientRect().height;
                            let e = getComputedStyle(document.querySelector(".postItem"));
                            let margin = parseFloat(e.marginTop);
                            let newPosition = tagPosition - crumbHeight - margin;
                            window.scrollBy({ top: newPosition, behavior: 'auto' });
                        }
                        this.isScrollAdjusted = true;
                    });
                }
            };
        }
        setupTopic() {
            PopForums.Ready(async () => {
                this.isReplyLoaded = false;
                this.isNewerPostsAvailable = false;
                this.lowPage = this.pageIndex;
                this.highPage = this.pageIndex;
                // signalR connections
                let service = await PopForums.MessagingService.GetService();
                let connection = service.connection;
                let self = this;
                // for all posts loaded but reply not open
                connection.on("fetchNewPost", function (postID) {
                    if (!self.isReplyLoaded && self.highPage === self.pageCount) {
                        fetch(PopForums.AreaPath + "/Forum/Post/" + postID)
                            .then(response => response.text()
                            .then(text => {
                            var t = document.createElement("template");
                            t.innerHTML = text.trim();
                            document.querySelector("#PostStream").appendChild(t.content.firstChild);
                        }));
                        self.lastVisiblePostID = postID;
                    }
                });
                // for reply already open
                connection.on("notifyNewPosts", function (theLastPostID) {
                    self.setMorePostsAvailable(theLastPostID);
                });
                connection.invoke("listenToTopic", this.topicID);
                this.connection = connection;
                document.querySelectorAll(".postItem img:not(.avatar)").forEach(x => x.classList.add("postImage"));
                this.scrollToPostFromHash();
                window.addEventListener("scroll", this.scrollLoad);
                // compensate for iOS losing selection when you touch the quote button
                document.querySelectorAll(".postBody").forEach(x => x.addEventListener("touchend", (e) => {
                    let selection = document.getSelection();
                    if (!selection || selection.rangeCount === 0 || selection.getRangeAt(0).toString().length === 0) {
                        return;
                    }
                    let range = selection.getRangeAt(0);
                    this.selectionAncestor = range.commonAncestorContainer;
                    this.documentFragment = range.cloneContents();
                }));
            });
        }
        loadReply(topicID, replyID, setupMorePosts) {
            if (this.isReplyLoaded) {
                this.scrollToElement("NewReply");
                return;
            }
            window.removeEventListener("scroll", this.scrollLoad);
            var path = PopForums.AreaPath + "/Forum/PostReply/" + topicID;
            if (replyID != null) {
                path += "?replyID=" + replyID;
            }
            fetch(path)
                .then(response => response.text()
                .then(text => {
                let n = document.querySelector("#NewReply");
                n.innerHTML = text;
                n.style.display = "block";
                this.scrollToElement("NewReply");
                this.isReplyLoaded = true;
                if (setupMorePosts) {
                    let self = this;
                    this.connection.invoke("getLastPostID", this.topicID)
                        .then(function (result) {
                        self.setMorePostsAvailable(result);
                    });
                }
                this.isReplyLoaded = true;
                this.commentReplyID = 0;
            }));
        }
        loadComment(topicID, replyID) {
            var n = document.querySelector("[data-postid*='" + replyID + "'] .commentHolder");
            const boxid = "commentbox";
            n.id = boxid;
            var path = PopForums.AreaPath + "/Forum/PostReply/" + topicID + "?replyID=" + replyID;
            this.commentReplyID = replyID;
            this.isReplyLoaded = true;
            fetch(path)
                .then(response => response.text()
                .then(text => {
                n.innerHTML = text;
                this.scrollToElement(boxid);
            }));
        }
        ;
        setAnswer(postID, topicID) {
            var model = { postID: postID, topicID: topicID };
            fetch(PopForums.AreaPath + "/Forum/SetAnswer/", {
                method: "POST",
                body: JSON.stringify(model),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                this.answerPostID = postID;
            });
        }
    }
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "isReplyLoaded", void 0);
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "answerPostID", void 0);
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "lowPage", void 0);
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "highPage", void 0);
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "isNewerPostsAvailable", void 0);
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "commentReplyID", void 0);
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "nextQuote", void 0);
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "isSubscribed", void 0);
    __decorate([
        PopForums.WatchProperty
    ], TopicState.prototype, "isFavorite", void 0);
    PopForums.TopicState = TopicState;
})(PopForums || (PopForums = {}));
var PopForums;
(function (PopForums) {
    class UserState extends PopForums.StateBase {
        constructor() {
            super();
            this.ScrollLoad = async () => {
                if (this.isNotificationEnd)
                    return;
                let streamEnd = document.querySelector("#NotificationBottom");
                if (!streamEnd) {
                    console.log("Can't find bottom of notifications.");
                    return;
                }
                let top = streamEnd.offsetTop;
                let viewEnd = this.list.scrollTop + this.list.clientHeight;
                let distance = top - viewEnd;
                if (!this.isLoadingNotifications && distance < 250 && !this.isNotificationEnd) {
                    await this.LoadMoreNotifications();
                }
            };
        }
        async initialize() {
            this.postImageIds = new Array();
            this.notificationService = new PopForums.NotificationService(this);
            await this.notificationService.initialize();
        }
        async LoadNotifications() {
            this.isLoadingNotifications = true;
            this.lastNotificationDate = new Date(2100, 1, 1);
            this.isNotificationEnd = false;
            this.notifications = new Array();
            await this.notificationService.LoadNotifications();
            this.isLoadingNotifications = false;
        }
        async MarkRead(contextID, notificationType) {
            await this.notificationService.MarkRead(contextID, notificationType);
        }
        async MarkAllRead() {
            await this.notificationService.MarkAllRead();
        }
        async LoadMoreNotifications() {
            this.isLoadingNotifications = true;
            await this.notificationService.LoadNotifications();
            this.isLoadingNotifications = false;
        }
    }
    __decorate([
        PopForums.WatchProperty
    ], UserState.prototype, "newPmCount", void 0);
    __decorate([
        PopForums.WatchProperty
    ], UserState.prototype, "notificationCount", void 0);
    __decorate([
        PopForums.WatchProperty
    ], UserState.prototype, "notifications", void 0);
    PopForums.UserState = UserState;
})(PopForums || (PopForums = {}));
